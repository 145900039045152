export class StartChat {
  static readonly type = '[Chat] Start chat';
}

export class StopChat {
  static readonly type = '[Chat] Stop chat';
}

export class ShowChatBubble {
  static readonly type = '[Chat] Show Bubble';
}

export class HideChatBubble {
  static readonly type = '[Chat] Hide Bubble';
}
